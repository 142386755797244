// Render Prop
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import queryString from 'query-string';
import axios from 'axios';
import Modal from 'react-modal';
import styled from 'styled-components';
import SignupSchema from '../utils/signupSchema';
import ThankYou from '../components/thankyou';
import NetworkError from '../components/networkerror';
import loader from '../images/loading-gif.gif';

// can't use a styled component here for the modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
};

const LoaderDiv = styled.div`
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 26px;
  padding: 20px;
  img {
    width: 75px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  grid-column: ${props => (props.fullWidth ? '1 / 3' : '')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  border-top: ${props =>
    props.topBorder ? `${props.topBorder} solid #eeeeee` : 'none'};
  padding-top: ${props => (props.topPadding ? props.topPadding : '0')};
  input {
    border: ${props => (props.noBorder ? 'none' : '')};
  }

  @media only screen and (max-width: 768px) {
    grid-column: 1/3;
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 14px;
  margin-bottom: 20px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
`;

const StyledButton = styled.button`
  display: block;
  width: 100%;
  height: 60px;
  font-size: 36px;
  border-radius: 4px;
  background-color: #5dad1e;
  color: #ffffff;
  text-align: center;
  grid-column: ${props => (props.fullWidth ? '1 / 3' : '')};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    border: 1px solid #5dad1e;
    color: #5dad1e;
  }
`;

const SubTotal = styled.p`
  line-height: 0.7;
`;

Modal.setAppElement('#___gatsby');

const UserForm = () => {
  const parsed = queryString.parse(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  const [transSuccess, setTransSuccess] = useState(false);
  const [networkFailed, setNetworkFailed] = useState(false);
  const [resData, setResData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [surchargeData, setSurchargeData] = useState({});
  const [surcharge, setSurcharge] = useState(0.0);
  const [interPaySalesData, setInterPaySalesData] = useState();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const ccSubmit = async data => {
    try {
      let apiLoginId = '4NwncL48n',
        transactionKey = '4kttF9e9K2s4Y4BT',
        apiUrl = 'https://api.authorize.net/xml/v1/request.api';

      // sets test keys if url param exists
      if (parsed.stage === '1') {
        apiLoginId = '38j6TbNq';
        transactionKey = '882ZQhj79r6JVq2f';
        apiUrl = 'https://apitest.authorize.net/xml/v1/request.api';
      }
      const postData = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: apiLoginId,
            transactionKey: transactionKey,
          },
          transactionRequest: {
            transactionType: 'authCaptureTransaction',
            amount: data.totalCharge.replace(/\$/g, ''),
            payment: {
              creditCard: {
                cardNumber: data.creditCard,
                expirationDate: data.expiration,
                cardCode: data.ccv,
              },
            },
            order: {
              invoiceNumber: data.invoice,
            },
            billTo: {
              firstName: data.firstName,
              lastName: data.LastName,
              company: data.company,
              address: data.billingAddress,
              city: data.city,
              state: data.state,
              zip: data.postalCode,
              country: 'USA',
            },
          },
        },
      };

      const response = await axios.post(apiUrl, postData);

      const formData = {
        amount: data.totalCharge,
        invoice: data.invoice,
        firstName: data.firstName,
      };
      const concatObj = await Object.assign({}, formData, response.data);
      setResData(concatObj);
      setTransSuccess(true);
      setModalIsOpen(false);
    } catch (error) {
      console.log(error);
      setNetworkFailed(true);
    }
  };

  const callInterpayments = async surchargeData => {
    try {
      let token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTQ2ODAyMjcsIm5hbWUiOiJwbGF0ZWF1LWRhdGEtc2VydmljZXMiLCJpZCI6IjFmM2E5MmVmLTk3ZGItNGU5YS1iMGU4LTRlYjI4NmM3MGVmMSIsImRhdGEiOlsicGxhdGVhdS1kYXRhLXNlcnZpY2VzIC0gcHJvZCJdfQ.EEOayiGNEVFeE0YdS7SBz7pwYgQ81SvUjStaEEpJ9WM';
      let apiUrl = 'https://api.interpayments.com/v1/ch';
      const { creditCard, amount, postalCode } = surchargeData;
      const postData = {
        region: postalCode.substring(0, 5),
        nicn: creditCard,
        amount: amount,
      };

      if (parsed.stage === '1') {
        token =
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTI0MjkxMjIsIm5hbWUiOiJwbGF0ZWF1LWRhdGEtc2VydmljZXMiLCJpZCI6IjU4NjhkYTBlLTE0ZDctNGY5NC04ZWFlLWE3ODNjYzZjY2FlMyIsImRhdGEiOlsicGxhdGVhdS10ZXN0LW5vbmV4cCJdfQ.cTQAiT0szACs6WcCcBuBjlu_0K2e0vl9vG1UfjYTVj4 ';
        apiUrl = 'https://api-test.interpayments.com/v1/ch';
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.post(apiUrl, postData, config);
      const { sTxId, transactionFee } = response.data;

      if (transactionFee) {
        setSurcharge(transactionFee.toFixed(2));
      } else {
        setSurcharge(0.0);
      }
      if (sTxId) {
        setInterPaySalesData(sTxId);
      }
    } catch (error) {
      console.log('callInterpayments -> error', error);
    }
  };

  const onBlurHandler = e => {
    const { name, value } = e.target;
    if (name === 'creditCard') {
      setSurchargeData({ ...surchargeData, [name]: value.substring(0, 6) });
    } else {
      setSurchargeData({ ...surchargeData, [name]: value });
    }
  };

  const interPaymentsZipValidation = postalCode => {
    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (
      postalCode.length >= 5 &&
      postalCode.length <= 10 &&
      regexp.test(postalCode)
    ) {
      return true;
    }
    return false;
  };

  const Surcharge = ({
    field, // { name, value, onChange, onBlur }
    form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    useEffect(() => {
      setFieldValue('surcharge', `$${surcharge}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surcharge, setFieldValue]);
    return <input type="text" {...field} {...props} />;
  };
  const TotalCharge = ({
    field, // { name, value, onChange, onBlur }
    form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    useEffect(() => {
      // checks if values.amount is not an empty string or a zero. If so then set the total amount as zero and update the surcharge to zero.
      if (values.amount !== '' && values.amount !== 0) {
        const parsedAmount = parseFloat(values.amount);
        const parsedSurcharge = parseFloat(surcharge);
        const parsedTotal = parsedAmount + parsedSurcharge;
        setFieldValue('totalCharge', `$${parsedTotal.toFixed(2)}`);
      } else {
        setFieldValue('totalCharge', `$${0.0}`);
        setSurcharge(0.0);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surcharge, setFieldValue, values.amount]);
    return <input type="text" {...field} {...props} />;
  };

  useEffect(() => {
    // once all three items are valid we run the API call and Update the submit button state to enable it
    const { creditCard, amount, postalCode } = surchargeData;
    if (creditCard && amount && postalCode) {
      if (
        creditCard.length === 6 &&
        amount > 0 &&
        interPaymentsZipValidation(postalCode)
      ) {
        callInterpayments(surchargeData);
      } else {
        setSurcharge(0.0);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surchargeData]);

  // conditionally render the components.
  if (transSuccess) {
    return <ThankYou result={resData} interPaySalesData={interPaySalesData} />;
  } else if (networkFailed) {
    return <NetworkError />;
  } else if (modalIsOpen) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <LoaderDiv>
          <img src={loader} alt="Loading..." />
          <p>We are processing your payment now...</p>
        </LoaderDiv>
      </Modal>
    );
  } else {
    return (
      <Formik
        initialValues={{
          invoice: '',
          amount: '',
          creditCard: '',
          expiration: '',
          ccv: '',
          company: '',
          firstName: '',
          lastName: '',
          billingAddress: '',
          city: '',
          state: '',
          postalCode: '',
          surcharge: '',
          totalCharge: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          openModal();
          setTimeout(() => {
            ccSubmit(values);
            setSubmitting(false);
          }, 800);
        }}
      >
        {({ isSubmitting, handleChange, values }) => (
          <StyledForm>
            <StyledLabel htmlFor="invoice">
              Invoice*
              <Field type="text" name="invoice" placeholder="Invoice#" />
              <StyledErrorMessage name="invoice" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="amount">
              Amount*
              <Field
                type="number"
                name="amount"
                placeholder="no dollar symbols required"
                onChange={e => {
                  handleChange(e);
                  onBlurHandler(e);
                }}
              />
              <StyledErrorMessage name="amount" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="creditCard" fullWidth>
              Card number*
              <Field
                type="number"
                name="creditCard"
                placeholder="XXXXXXXXXXXX1234"
                onBlur={e => {
                  handleChange(e);
                  onBlurHandler(e);
                }}
              />
              <StyledErrorMessage name="creditCard" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="expiration">
              Expiration*
              <Field type="text" name="expiration" placeholder="MM/YYYY" />
              <StyledErrorMessage name="expiration" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="ccv">
              CV code*
              <Field type="text" name="ccv" placeholder="ex: 123" />
              <StyledErrorMessage name="ccv" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="company" fullWidth>
              Account name
              <Field type="text" name="company" placeholder="Company" />
              <StyledErrorMessage name="company" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="firstName">
              First name on card*
              <Field type="text" name="firstName" placeholder="First Name" />
              <StyledErrorMessage name="firstName" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="lastName">
              Last name on card*
              <Field type="text" name="lastName" placeholder="Last Name" />
              <StyledErrorMessage name="lastName" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="billingAddress" fullWidth>
              Billing address*
              <Field
                type="text"
                name="billingAddress"
                placeholder="ex: 22 Baker ST"
              />
              <StyledErrorMessage name="billingAddress" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="city" fullWidth>
              City*
              <Field type="text" name="city" placeholder="City" />
              <StyledErrorMessage name="city" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="state">
              State*
              <Field component="select" name="state">
                <option value="">Select State...</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">Dist of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Field>
              <StyledErrorMessage name="state" component="div" />
            </StyledLabel>

            <StyledLabel htmlFor="postalCode">
              Postal code*
              <Field
                type="text"
                name="postalCode"
                placeholder="ex: 82435"
                onChange={e => {
                  handleChange(e);
                  onBlurHandler(e);
                }}
              />
              <StyledErrorMessage name="postalCode" component="div" />
            </StyledLabel>

            <StyledLabel>
              SubTotal:
              <SubTotal>
                ${values.amount ? values.amount.toFixed(2) : 0.0}
              </SubTotal>
            </StyledLabel>
            {surcharge > 0 && (
              <StyledLabel noBorder>
                Card Transaction Fee:
                <Field
                  name="surcharge"
                  component={Surcharge}
                  placeholder="0.00"
                  disabled
                  style={{ fontSize: '18px', paddingLeft: 0 }}
                />
              </StyledLabel>
            )}

            <StyledLabel
              fullWidth
              bold
              noBorder
              topBorder="2px"
              topPadding="10px"
            >
              Total:
              <Field
                name="totalCharge"
                component={TotalCharge}
                placeholder="$0.00"
                disabled
                style={{ fontSize: '20px', fontWeight: 'bold', paddingLeft: 0 }}
              />
            </StyledLabel>
            <StyledErrorMessage name="totalCharge" component="div" />
            <StyledButton type="submit" disabled={isSubmitting} fullWidth>
              Submit
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    );
  }
};

export default UserForm;
