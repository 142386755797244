import React from 'react';
import axios from 'axios';
import queryString from 'query-string';

const Result = props => {
  const parsed = queryString.parse(typeof window !== 'undefined' ? window.location.search : '');
  const result = props.result,
    messageCode = result.messages.message[0].code,
    authTrans = result.transactionResponse,
    sTxId = props.interPaySalesData;

  const callInterpaymentsSale = async () => {
    try {
      let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTQ2Nzk5MTksIm5hbWUiOiJzdWl0ZWQtY29ubmVjdG9yIiwiaWQiOiI5NDFmMTZhNy0xZTY3LTRmMTYtYTMxNy00YWEwMmMxN2MzNGYiLCJkYXRhIjpbInN1aXRlZC1jb25uZWN0b3IgLSBwcm9kIl19.3BdChYxciExU8LR8-OO4MusslveyWKhl1omR2g53Vv8';
      let apiUrl = 'https://api.interpayments.com/v1/ch/sale';
      const postData = {
        sTxId: sTxId,
      };

      if (parsed.stage === '1') {
        token =
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTI0Mjk1MzcsIm5hbWUiOiJzdWl0ZWQtY29ubmVjdG9yIiwiaWQiOiI4ZjIxZjg2OS0zZDM2LTRhMTAtYTZjMC0xN2MwZjZiYjIwMTciLCJkYXRhIjpbInN1aXRlZGNvbm5lY3RvciAtIHRlc3QgLSBub25leHAiXX0.J0ZxdCUM4okrRdvJ4gymcKTRNfcRzFzqQnu5aeZ54Ns';
        apiUrl = 'https://api-test.interpayments.com/v1/ch/sale';
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await axios.post(apiUrl, postData, config);
    } catch (error) {
      console.log('callInterpaymentsSale -> error', error);
    }
  };

  if (typeof authTrans == 'undefined') {
    return (
      <div>
        <h1>Our apologies</h1>
        <h3>There was an issue completing your request.</h3>
        <p>
          Please try again or contact us at{' '}
          <a href="mailto:accounting@suitedconnector.com">
            accounting@suitedconnector.com
          </a>
          . <br />
          Your credit card has not been charged at this time. <br /> Error Code:{' '}
          {messageCode}
        </p>
      </div>
    );
  } else if (
    authTrans.messages !== '' &&
    authTrans.messages !== undefined &&
    authTrans.messages != null
  ) {
    callInterpaymentsSale();

    return (
      <div>
        <h1>Thank you {result.firstName}</h1>
        <p>
          Payment for invoice: {result.invoice} in the amount
          of <strong>{result.amount}</strong> was received.
        </p>
        <p>
          Authorization Code: <strong>{authTrans.authCode}</strong>
        </p>
        <p>
          Transaction ID: <strong>{authTrans.transId}</strong>
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Our apologies</h1>
        <h3>{authTrans.errors[0].errorText}</h3>
        <p>Please try again. Error code: {authTrans.errors[0].errorCode}</p>
      </div>
    );
  }
};

export default Result;
