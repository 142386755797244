import * as Yup from 'yup';
import valid from 'card-validator';

const SignupSchema = Yup.object().shape({
  invoice: Yup.string()
    .label('Invoice')
    .min(2, 'Invoice number is too Short!')
    .max(70, 'Invoice number is too Long!')
    .required('Invoice # is Required'),
  amount: Yup.number()
    .required('Amount is Required')
    .max(99999, 'Transactions are limited to less than 100k'),
  creditCard: Yup.string()
    .min(13, 'Not enough numbers in your credit card')
    .test(
      'test-number', // this is used internally by yup
      'Credit card number is invalid', //validation message
      value => valid.number(value).isPotentiallyValid
    ) // return true false based on validation
    .required('Credit card number is required'),
  expiration: Yup.string()
    .min(7, 'Please format date as: MM/YYYY')
    .test(
      'test-expiration', // this is used internally by yup
      'Expiration date is invalid', //validation message
      value => valid.expirationDate(value).isPotentiallyValid
    ) // return true false based on validation
    .required('Expiration date is required'),
  ccv: Yup.string()
    .min(3)
    .max(4)
    .test(
      'test-ccv', // this is used internally by yup
      'CCV is invalid', //validation message
      value => valid.cvv(value, 4).isPotentiallyValid
    ) // return true false based on validation
    .required('CCV is required'),
  company: Yup.string(),

  firstName: Yup.string()
    .min(2, 'First name is too short')
    .max(70, 'First name is too long')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Last name is too short')
    .max(70, 'Last name is too long')
    .required('Last name is required'),
  billingAddress: Yup.string()
    .min(5, 'Billing address is too short')
    .max(70, 'Billing address is too long')
    .required('Billing address is required'),
  city: Yup.string()
    .max(50, 'City is too long')
    .required('City is required'),
  state: Yup.string()
    .max(2, 'State is too long')
    .required('State is required'),
  postalCode: Yup.string()
    .min(5, 'Zip code must be 5 characters')
    .max(10, 'ZIP+4 ex: 82435-1234')
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Must be a valid zip')
    .test(
      'test-postalcode', // this is used internally by yup
      'Postal Code is invalid', //validation message
      value => valid.postalCode(value, { minLength: 5 }).isValid
    ) // return true false based on validation
    .required('Postal Code is required'),
  totalCharge: Yup.string()
    .min(1, 'Total Amount must be greater than zero')
    .required('Total Amount is required'),
});

export default SignupSchema;
