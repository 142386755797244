import React from "react"

const Networkerror = () => {
  return (
    <>
      <h1>Our apologies</h1>
      <h3>There was an issue completing your request.</h3>
      <p>
        Please try again or contact us at{" "}
        <a href="mailto:accounting@suitedconnector.com">
          accounting@suitedconnector.com
        </a>
        . <br />
        Your credit card has not been charged at this time.
      </p>
    </>
  )
}

export default Networkerror 
