import React from 'react';
import styled from 'styled-components';

const StyledDisclosure = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 20px;
  text-align: center;
  p {
    font-size: 14px;
    line-height: 21px;
    color: #6d6d6d;
  }
`;

const Disclosure = () => {
  return (
    <StyledDisclosure>
      <p>
        If you choose to pay by credit card, a processing fee may be passed on.
        Company does not determine or profit from the processing fee. The range
        of fees is 2% – 4% and is determined by factors outside of Company
        control including but not limited to card brand, address, and reward
        type used. The exact charge will be provided at checkout. No processing
        fee is assessed to ACH or Debit card transactions or where prohibited by
        law.
      </p>
    </StyledDisclosure>
  );
};

export default Disclosure;
