import React from 'react'
import styled from 'styled-components'
import Result from './result'

const StyledResult = styled.div`
  text-align: center;
`

const ThankYou = props => {
  return (
    <StyledResult>
      <Result result={props.result} interPaySalesData={props.interPaySalesData} />
    </StyledResult>
  )
}

export default ThankYou
